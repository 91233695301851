import type { LocaleObject } from '@nuxtjs/i18n';
import { setHeader } from 'h3';
import { useGeodataStore } from '~/store/geodata';

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.client) {
    return;
  }

  const event = useRequestEvent();

  if (!event) {
    console.log('[i18n] event does not exist');
    return;
  }

  const nuxtApp = useNuxtApp();
  const i18n = nuxtApp.$i18n;
  const { geoLocate } = useGeodataStore();
  const cookie = useCookie('i18n_redirected');

  if (cookie.value) {
    console.log('[i18n] cookie already exists');
    return;
  }

  const placeholder = 'xx';
  const browserLocale = i18n.getBrowserLocale() || `${placeholder}-${placeholder}`;
  const [browserLang, browserCountry] = browserLocale.toLowerCase().split('-');
  const geolocation = geoLocate(event);
  const geoCountry = (geolocation.location.country || placeholder).toLowerCase();

  console.log('[i18n]', { browserCountry, browserLang, geoCountry });

  const codes = [
    `${browserLang || placeholder}-${geoCountry}`,
    `en-${geoCountry}`,
    `${browserLang || placeholder}-${browserCountry || placeholder}`,
    `en-${browserCountry || placeholder}`,
    i18n.defaultLocale,
  ];

  console.log('[i18n] sequence', codes.join(', '));

  const attempts: string[] = [];
  let locale: LocaleObject | undefined;

  for (const code of codes) {
    if (/xx/.test(code)) {
      continue;
    }

    attempts.push(code);

    locale = i18n.locales.value.find((locale) => locale.code.toLowerCase() === code.toLowerCase());

    if (locale) {
      break;
    }
  }

  if (!locale) {
    console.log('[i18n] could not identify locale');
    return;
  }

  const headerValue = attempts.join(', ');

  setHeader(event, 'X-Solo-I18n-Sequence', codes.join(', '));
  setHeader(event, 'X-Solo-I18n-Attempts', headerValue);

  console.log('[i18n] selection', headerValue);

  await i18n.setLocale(locale.code);
});
