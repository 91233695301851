<template>
  <a ref="linkRef" :href="`#${target}`" :class="['anchor', variant]">
    <pre>{{ text }}</pre>

    <svg width="147" height="146" viewBox="0 0 147 146" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow">
      <g clip-path="url(#clip0_12480_39249)">
        <path
          d="M2.47636 48.8363C41.2515 41.1439 121.665 45.8714 133.116 126.321"
          stroke="#F9F2E6"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M144.107 108.107L132.815 127.145L118.03 109.796"
          stroke="#F9F2E6"
          stroke-width="3"
          stroke-linecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_12480_39249">
          <rect width="147" height="146" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </a>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

defineProps({
  text: {
    type: String,
    required: true,
  },
  target: {
    type: String,
    required: false,
    default: null,
  },
  variant: {
    type: String,
    required: false,
    default: undefined,
  },
});

const linkRef = ref<HTMLAnchorElement>(null!);

onMounted(() => {
  const link = linkRef.value;

  link.addEventListener('click', (event) => {
    event.preventDefault();

    const targetID = link.getAttribute('href')!.substring(1);
    let targetElement: HTMLElement | null | undefined = document.getElementById(targetID);
    if (!targetElement) {
      targetElement = link.closest('.section-wrapper')?.nextElementSibling as HTMLElement;
    }

    const header = document.querySelector('header.header-container .header-content') as HTMLElement;
    const headerClientRect = header?.getBoundingClientRect() || { height: 0, y: 0 };

    const offset = targetElement.offsetTop - (headerClientRect.height + headerClientRect.y);

    if (targetElement) {
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  });
});
</script>

<style lang="scss" scoped>
.anchor {
  display: inline-flex;
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: #fff;
  font-family: local-vars.$ff-hlp;
  font-size: 1.438rem;
  font-weight: 750;
  text-transform: uppercase;
  align-self: flex-end;
}

pre {
  font-family: inherit;
}

.arrow {
  width: 147px;
  height: 146px;
  margin-top: -35px;
  margin-left: 10px;
}

.variant-2 {
  justify-self: center;
  transform: translateY(85px);
  font-size: 0.75rem;

  @media (max-width: calc(#{map.get(local-vars.$breakpoints, 'medium-large')})) {
    .arrow {
      width: 100px;
      margin-top: -50px;
    }
  }

  @media (min-width: calc(#{map.get(local-vars.$breakpoints, 'medium-large')})) {
    font-size: 1.438rem;
  }
}
</style>
