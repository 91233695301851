<script lang="ts" setup>
import { ImageOptions } from '~/composables/cloudinary';

type ImageQuality = NonNullable<ImageOptions['quality']>;
type ImageClassification = 'placeholder' | 'primary' | 'hover';

const emit = defineEmits<{
  (event: 'cld-image-loaded', payload: { src: string }): void;
}>();

const props = defineProps({
  publicId: {
    type: String,
    default: undefined,
  },
  hoverPublicId: {
    type: String as () => string | undefined,
    default: undefined,
  },
  options: {
    type: Object as () => ImageOptions | undefined,
    default: undefined,
  },
  isVideo: {
    type: Boolean,
    default: false,
  },
  alt: {
    type: String,
    default: '',
  },
  imageClassesOverride: {
    type: Array as () => (string | Record<string, boolean>)[],
    default: () => [],
  },
  lazyLoad: {
    type: Boolean,
    default: true,
  },
  enableLogging: {
    type: Boolean,
    default: false,
  },
  aspectRatioOverride: {
    type: String,
    default: undefined,
  },
  theme: {
    type: String as () => 'light' | 'dark',
    default: 'light',
  },
});

const cloudinary = useCloudinary(props.enableLogging);

const loaded = ref(false);
const hoverLoaded = ref(false);
const isHover = ref(false);
const quality = ref<ImageQuality>(props.options?.quality || 'auto:eco');

const hoverImg = computed(() => {
  const publicId = props.isVideo && props.hoverPublicId ? `${props.hoverPublicId}.jpg` : props.hoverPublicId;
  const method = props.isVideo ? cloudinary.generateVideoUrl : cloudinary.generateImageUrl;
  return publicId ? method(publicId, { ...props.options, quality: quality.value }) : undefined;
});

const primaryImg = computed(() => {
  const publicId = props.isVideo ? `${props.publicId}.jpg` : props.publicId;
  const method = props.isVideo ? cloudinary.generateVideoUrl : cloudinary.generateImageUrl;
  return publicId ? method(publicId, { ...props.options, quality: quality.value }) : undefined;
});

const containerStyles = computed(() => {
  const aspectRatio =
    props.aspectRatioOverride !== undefined
      ? props.aspectRatioOverride
      : props.options?.width && props.options?.height
      ? `${props.options.width} / ${props.options.height}`
      : 'auto';
  return {
    aspectRatio,
  };
});

const log = (message: unknown) => {
  if (props.enableLogging) {
    console.log(message);
  }
};

const preloadImage = async function (src: string, type: ImageClassification) {
  const img = new Image();
  const startTime = performance.now();
  img.src = src;
  img.loading = 'lazy';
  // wait for 50ms to alleviate race condition on complete vs onload.
  await new Promise((resolve) => setTimeout(resolve, 50));
  if (src && img.complete) {
    log(`Image cached: URL: ${src}`);
    emit('cld-image-loaded', { src });
    if (type === 'primary') loaded.value = true;
    if (type === 'hover') hoverLoaded.value = true;
  } else {
    if (type === 'primary') loaded.value = false;
    if (type === 'hover') hoverLoaded.value = false;
    // loaded.value = false;
    // hoverLoaded.value = false;
    img.onload = () => {
      const endTime = performance.now();
      log(`Image loaded: URL: ${src}, Time: ${endTime - startTime} ms`);
      emit('cld-image-loaded', { src });
      if (type === 'primary') loaded.value = true;
      if (type === 'hover') hoverLoaded.value = true;
    };
    img.onerror = () => {
      log(`Image failed to load: URL: ${src}`);
    };
  }
  log('');
};

const preloadImages = function () {
  if (primaryImg.value) preloadImage(primaryImg.value, 'primary');
  if (hoverImg.value) preloadImage(hoverImg.value, 'hover');
};

const preloadServerImages = function () {
  if (process.server) {
    loaded.value = true;
    hoverLoaded.value = true;
    useHead({
      link: [
        ...(primaryImg.value ? [{ hid: 'preload', rel: 'preload', href: primaryImg.value, as: 'image' as const }] : []),
        ...(hoverImg.value ? [{ hid: 'preload', rel: 'preload', href: hoverImg.value, as: 'image' as const }] : []),
      ],
    });
  }
};

const width = computed(() => props.options?.width);
const height = computed(() => props.options?.height);
const imageClasses = computed(() => {
  const defaults: { [key: string]: boolean } = {};
  props.imageClassesOverride.forEach((cls) => {
    if (typeof cls === 'string') {
      defaults[cls] = true;
    } else {
      Object.keys(cls).forEach((key) => {
        defaults[key] = cls[key];
      });
    }
  });
  return defaults;
});

watch(
  () => props.publicId,
  (newPublicId, oldPublicId) => {
    if (newPublicId && newPublicId !== oldPublicId) preloadImages();
  }
);

onMounted(() => {
  preloadImages();
});

preloadServerImages();
</script>

<template>
  <div :class="['cloudinary-image-container', `${theme}-theme`]" :style="containerStyles">
    <Transition name="cld-loading">
      <div v-if="!loaded" class="cld-loading-container">
        <div class="skeleton"></div>
      </div>
    </Transition>
    <img
      :src="isHover && hoverImg ? hoverImg : primaryImg"
      :class="imageClasses"
      :width="width"
      :height="height"
      :alt="alt"
      :loading="lazyLoad ? 'lazy' : 'eager'"
      @load="loaded = true"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}
.cloudinary-image-container {
  position: relative;
  .cld-loading-container {
    position: absolute;
    z-index: map.get(local-vars.$zindex, 'page');
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .skeleton {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.cld-loading-enter-active,
.cld-loading-leave-active {
  transition: opacity 250ms ease-in-out;
}

.cld-loading-enter-from,
.cld-loading-leave-to {
  opacity: 0;
}
.dark-theme {
  .skeleton {
    background: linear-gradient(-45deg, #eeeeee00 40%, #ffffff6c 50%, #eeeeee00 60%);
    background-size: 311%;
    background-position-x: 100%;
    animation: shimmer 1s infinite linear;
  }
}
</style>
