<template>
  <div v-if="optionsFull?.length > 0" id="options-section" class="options-container">
    <div v-for="(optType, optTypeIdx) in optionsFull" :key="`${optType?.id} + ${optTypeIdx}`">
      <div
        :class="['selected-title', { color: isColorOption(optType) }, { 'button-opt': isButtonOptionType(optType) }]"
      >
        <template v-if="isColorOption(optType) || isButtonOptionType(optType)">
          <div>
            <span class="bold">{{ optType.display_name }}: </span>
            <span>{{ getSelectedOptionLabel(optTypeIdx) }}</span>
          </div>
          <slot v-if="isButtonOptionType(optType)" name="size-link" />
        </template>
        <template v-else> {{ optType.display_name }}: </template>
      </div>

      <div :class="['options-wrapper flex-inline flex-wrap mgn-btm-1', { color: isColorOption(optType) }]">
        <template v-if="isColorOption(optType) || isButtonOptionType(optType, 'display_name')">
          <div
            v-for="(opt, optionIndex) in !~numberOptionsToShow
              ? optType.option_values
              : optType.option_values.slice(0, numberOptionsToShow)"
            :key="opt?.id"
            :style="isButtonOptionType(optType, 'display_name') ? buttonStyleWidth(optType.option_values.length) : ''"
            class="option option-mobile-wrapper"
          >
            <ProductColorOptions
              v-if="isColorOption(optType)"
              :option="opt"
              :is-selected="opt?.id === state.selectedOptionIds?.[optTypeIdx]"
              :is-disabled="checkIfDisabled(opt?.id, optTypeIdx)"
              :index="optionIndex"
              :is-pdp="isPdp"
              @set-child="setSelectedVariantId(optTypeIdx, opt?.id)"
            />
            <ProductButtonOption
              v-else-if="isButtonOptionType(optType, 'display_name') && !isHlpCarousel"
              :option="opt"
              :is-selected="opt?.id === state.selectedOptionIds?.[optTypeIdx]"
              :is-disabled="checkIfDisabled(opt?.id, optTypeIdx)"
              :selected-option="{}"
              :index="optionIndex"
              :is-pdp="isPdp"
              @set-child="setSelectedVariantId(optTypeIdx, opt?.id)"
            />
          </div>
          <span
            v-if="~numberOptionsToShow && numberOptionsToShow < optType.option_values.length && !isHlpCarousel"
            class="rest-options-number"
          >
            {{ '+' + (optType.option_values.length - numberOptionsToShow) }}
          </span>
          <a
            v-if="showComparisonChartLink && isButtonOptionType(optType) && isSizeOption(optType, 'display_name')"
            href="#product-comparison"
            class="size-link ul"
            @click="scrollToElementById('#product-comparison', $event)"
          >
            Compare Sizes
          </a>
        </template>
        <template v-if="isButtonOptionType(optType, 'display_name')">
          <select
            v-model="state.selectedOptions[optTypeIdx]"
            class="options-select-mobile"
            @click.prevent
            @change="setSelectedVariantId(optTypeIdx, $event, true)"
          >
            <option
              v-for="opt in optType.option_values"
              :key="opt?.id"
              :value="opt"
              :selected="opt?.id === state.selectedOptionIds?.[optTypeIdx]"
              :disabled="checkIfDisabled(opt?.id, optTypeIdx)"
              :class="{ selected: opt?.id === state.selectedOptionIds?.[optTypeIdx] }"
            >
              {{ getLabel(opt) }}
            </option>
          </select>
        </template>

        <div v-else-if="isSelectOptionType(optType, 'display_name')" :class="['variant-wrapper']">
          <!-- click prevent stops product card navigation to pdp when selecting variants -->
          <select
            v-model="state.selectedOptions[optTypeIdx]"
            @click.prevent
            @change="setSelectedVariantId(optTypeIdx, $event, true)"
          >
            <option
              v-for="opt in optType.option_values"
              :key="opt?.id"
              :value="opt"
              :selected="opt?.id === state.selectedOptionIds?.[optTypeIdx]"
              :disabled="checkIfDisabled(opt?.id, optTypeIdx)"
              :class="{ selected: opt?.id === state.selectedOptionIds?.[optTypeIdx] }"
            >
              {{ getLabel(opt) }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useProductDataStore } from '~/store/product-data';
import { scrollToElementById } from '~/util/eventHandler';

const props = defineProps({
  optionDefaultOverrideId: {
    type: Number,
    default: undefined,
  },
  optionDefaultOverrideSku: {
    type: String,
    default: undefined,
  },
  isHlpCarousel: {
    type: Boolean,
    default: false,
  },
  productVariations: {
    type: Array as () => any[], // TODO - type
    required: true,
  },
  // Base Product SKU
  sku: {
    type: String,
    required: true,
  },
  showComparisonChartLink: {
    type: Boolean,
    required: false,
    default: false,
  },
  isPdp: {
    type: Boolean,
    default: false,
  },
  existingChoice: {
    type: Object,
    default: undefined,
    required: false,
  },
  numberOptionsToShow: {
    type: Number,
    default: -1,
  },
});

const emit = defineEmits(['product-options:update', 'modifiers-changed', 'product-variant-index']);

const state = reactive({
  selectedOptionIds: [] as number[], // Array of selected option ids
  selectedOptions: [] as any[], // Array of selected option objects
  defaultOptionsSet: false,
});
const variantBcId = ref<number | undefined>(undefined); // BC Id of selected variant

const commonProductData = useProductDataStore();

const options = computed(() => commonProductData.getOptionsBySku(props.sku));
const optionsFull = computed(() => options.value?.options_full);
const optionValues = computed(() => options.value?.option_values);
const disabledOptions = computed<{ [key: number]: { [key: number]: boolean } }>(() => {
  const disabledOption = {} as any;
  const disabledOptions = props.productVariations?.filter((variant) =>
    typeof variant?.variant_disabled === 'boolean' ? variant.variant_disabled : false
  );
  disabledOptions?.forEach((variantOption: any) => {
    const variantId = variantOption?.bc_variant?.id;
    const foundStoreMatch = optionValues?.value?.find((opt: any) => opt?.id === variantId);
    const numOptionTypes = foundStoreMatch?.option_values?.length;
    const baseOptionId = foundStoreMatch?.option_values?.[0]?.id;

    return foundStoreMatch?.option_values?.forEach((option: any, i: number) => {
      // index 0 is base, with sub items
      if (i === 0 && !disabledOption[option.id]) {
        disabledOption[baseOptionId] = {};
        if (numOptionTypes === 1) {
          disabledOption[option?.id] = true;
        }
      } else if (!disabledOption[baseOptionId][option.id]) {
        disabledOption[baseOptionId][option.id] = true;
      }
    });
  });
  return disabledOption;
});

onMounted(() => {
  defaultSetup();
});

// after mounted
watch(variantBcId, (updatedId) => {
  emit('product-options:update', findMatchingOptionWithId(updatedId));
  emit(
    'modifiers-changed',
    optionsFull.value?.map((opt: any, i: number) => {
      const findOption = opt?.option_values?.find((optVal: any) => optVal?.id === state.selectedOptionIds[i]);
      return {
        name: opt.display_name,
        // sku: opt.sku,
        option_id: opt?.id,
        option_value: findOption?.label,
        value: findOption?.label,
        valueId: state.selectedOptionIds[i],
      };
    })
  );
});

watch(
  options,
  () => {
    defaultSetup();
  },
  {
    deep: true,
  }
);

function defaultSetup() {
  if (props.optionDefaultOverrideSku) {
    // selected some variant combination on another page
    setOptionsWithVariantSku(props.optionDefaultOverrideSku);
  } else if (props.optionDefaultOverrideId) {
    // console.log('props.optionDefaultOverrideId', props.optionDefaultOverrideId);
    // selected some variant combination on another page
    setOptionsWithVariantId(props.optionDefaultOverrideId);
  } else if (props.existingChoice) {
    state.selectedOptionIds[0] = props.existingChoice?.option_values?.[0]?.id;
    state.selectedOptionIds[1] = props.existingChoice?.option_values?.[1]?.id;
  } else {
    // get first option for each option type
    state.selectedOptionIds = optionsFull?.value?.map((item: any) => item?.option_values?.[0]?.id) || [];
    state.selectedOptions = optionsFull?.value?.map((item: any) => item?.option_values?.[0]) || [];
    variantBcId.value = getVariantBcId();
  }
}

/**
 * @param index
 * @param id either id or for <select> the HTMLOptionsCollection options array
 * @param isSelect
 */
function setSelectedVariantId(index: number, id: number | any, isSelect = false) {
  let idCopy = id;
  if (isSelect) {
    const selectOptions = id?.target?.options;
    const selectedIndex = selectOptions.selectedIndex;
    idCopy = selectOptions[selectedIndex]?._value?.id;
  }
  state.selectedOptionIds[index] = idCopy;
  const foundSelectOption = optionsFull.value?.[index].option_values.find((opt: any) => opt?.id === idCopy);
  state.selectedOptions[index] = foundSelectOption;
  // console.log('FOUND', idCopy, foundSelectOption);
  const newBcId = getVariantBcId();
  // console.log('NEW ID', newBcId);
  variantBcId.value = newBcId;
}

function getVariantBcId(): number | undefined {
  let filtered = [...(optionValues.value || [])];
  state.selectedOptionIds?.forEach((item, i) => {
    filtered = filtered.filter((opt: any) => {
      return opt?.option_values[i]?.id === item;
    });
  });
  const found = filtered?.[0];
  return found?.id;
}
function setOptionsWithVariantId(varId: number) {
  // inverse map to set option indexes
  const matchingProduct = findMatchingOptionWithId(varId);
  for (let i = 0; i < optionsFull?.value?.length; i++) {
    setSelectedVariantId(i, matchingProduct?.option_values[i].id);
  }
  // must set after selectedOptionIds and selectedOptions so state is present for variantBcId.value watcher
  // console.log('matching prod id', matchingProduct);
  variantBcId.value = matchingProduct.id;
}
function setOptionsWithVariantSku(varSku: string) {
  // inverse map to set option indexes
  const matchingProduct = findMatchingOptionWithSku(varSku);
  for (let i = 0; i < optionsFull?.value?.length; i++) {
    setSelectedVariantId(i, matchingProduct?.option_values[i].id);
  }
  // must set after selectedOptionIds and selectedOptions so state is present for variantBcId.value watcher
  // console.log('matching prod sku', matchingProduct);
  variantBcId.value = matchingProduct?.id;
}
function findMatchingOptionWithId(id?: number): any {
  const found = optionValues?.value?.find((option: any) => option?.id === id);
  return found;
}
function findMatchingOptionWithSku(sku: string | null): any {
  const found = optionValues?.value?.find((option: any) => option?.sku === sku);
  return found;
}
function checkIfDisabled(_optionId: number, _optTypeIdx: number): boolean {
  // for products with multiple option types, only disable if not first option, which we would consider "base"
  // see nike where color is base and sizes are disabled
  if (state.selectedOptionIds?.length > 1 && disabledOptions?.value[state.selectedOptionIds[0]] && _optTypeIdx !== 0) {
    return disabledOptions?.value[state.selectedOptionIds[0]][_optionId];
  } else if (state.selectedOptionIds?.length === 1) {
    // if single option only, disable if set in bc
    return disabledOptions?.value[_optionId] as any;
  }
  return false;
}
function getSelectedOptionLabel(optTypeIdx: number | null) {
  if (optTypeIdx === null) return undefined;
  return optionsFull.value?.[optTypeIdx]?.option_values?.find(
    (val: any) => val?.id === state.selectedOptionIds?.[optTypeIdx]
  )?.label;
}
function isAmountOption(option: any, attr = 'option_display_name'): boolean {
  return option[attr]?.toLowerCase() === 'amount';
}
function checkType(type: string, option: any) {
  return type?.toLowerCase() === option?.type;
}
function isColorOption(option: any): boolean {
  return checkType('swatch', option);
}
function isSizeOption(option: any, attr = 'option_display_name'): boolean {
  return option[attr]?.toLowerCase() === 'size' || option[attr]?.toLowerCase() === 'select size';
}
function isModelOption(option: any, attr = 'option_display_name'): boolean {
  return option[attr]?.toLowerCase() === 'model';
}
function isCollegeLogo(option: any, attr = 'option_display_name'): boolean {
  return option[attr]?.toLowerCase() === 'college logo selection';
}
function isButtonOptionType(option: any, attr = 'option_display_name'): boolean {
  return isAmountOption(option, attr) || checkType('rectangles', option) || checkType('radio_buttons', option);
}
function buttonStyleWidth(numOptions: number): string {
  const maxCols = 3;
  let widthArea = 100; // 100% of the container
  if (numOptions === 1) widthArea = 50;
  const numCols = numOptions > maxCols ? maxCols : numOptions;
  const percent = widthArea / numCols;
  return `width: calc(${percent}% - ${(numCols - 1) * 8}px);`; // half of the margin
}
function isSelectOptionType(option: any, attr = 'option_display_name') {
  return (
    checkType('dropdown', option) ||
    isSizeOption(option, attr) ||
    isCollegeLogo(option, attr) ||
    isModelOption(option, attr)
  );
}
function getLabel(option: any): string | undefined {
  return option?.label;
}
</script>

<style lang="scss" scoped>
.options-select-mobile {
  display: none;
}
.rest-options-number {
  color: #767676;
  font-size: 0.75rem;
  line-height: 16px;
  font-weight: 400;
}
.size-link {
  color: $color-primary-600;
  font-size: 0.875rem;
  @include local-mixins.desktop {
    font-size: 1rem;
  }
}
.options-container {
  margin-bottom: 0;
}
.options-wrapper {
  margin-top: 0.5rem;
  column-gap: 16px;
  row-gap: 16px;
  &.color {
    column-gap: 6px;
    row-gap: 4px;
    .option {
      margin-bottom: auto;
    }
  }
}
:deep(.variant-wrapper) {
  .option {
    padding: 16px;
    font-size: 0.875rem;
    font-weight: 500;
    &:focus-visible,
    &:focus {
      outline: none;
    }
    &.has-option-desc {
      padding: 7px 16px;
    }
  }
  &.opt-button:not(.selected) {
    border: 1px solid $color-neutral-cool-200;
    border-radius: 4px;
    &:hover {
      border: 1px solid $color-neutral-black;
    }
  }
  .select-expanded {
    min-width: #{local-functions.rem-calc(250)};
  }
  &.selected {
    border: $color-primary-500 2px solid;
    border-radius: 4px;
  }
}
.selected-title {
  font-size: 1rem;
  line-height: 150%;
  @include local-mixins.tablet_and_mobile {
    font-size: 0.875rem;
  }
  &:not(.color):not(.button-opt) {
    font-weight: 500;
  }
}
.additional-items {
  margin-left: 10px;
  font-size: 0.75rem;
  line-height: 150%;
}
</style>
