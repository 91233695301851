import { H3Event, getRequestHeader } from 'h3'; // getRequestHeader import not needed for prod/pseudo-prod, but local dev needs it
// geodata will not populate if not ran in minifalre/wrangler

type CFH3Event = {
  context: {
    cf?: {
      city?: string;
      region?: string;
      regionCode?: string;
      postalCode?: string;
      country?: string;
    };
  };
};

export interface GeoData {
  ip: string | undefined;
  location: {
    city: string | undefined;
    region: string | undefined;
    regionCode: string | undefined;
    postalCode: string | undefined;
    country: 'xx' | 'XX' | string | undefined;
  };
  fbp?: string;
  fbc?: string;
}

export const useGeodataStore = defineStore('geodata', {
  state: () => ({
    geo: {} as GeoData,
  }),

  getters: {
    countryCode(state) {
      return state.geo?.location?.country;
    },
  },

  actions: {
    setGeo(geo: any) {
      this.geo = geo;
    },
    geoLocate(event?: H3Event) {
      const { context: { cf } = { cf: undefined } } = (event as CFH3Event) || ({} as CFH3Event);
      const ip = event
        ? getRequestHeader(event, 'x-real-ip') || getRequestHeader(event, 'CF-Connecting-IP')
        : undefined;
      const geoData = {
        ip,
        location: {
          city: cf?.city,
          region: cf?.region,
          regionCode: cf?.regionCode,
          postalCode: cf?.postalCode,
          // if CF does not know the country, 'xx' is used as a value
          country: cf?.country,
        },
      };
      this.geo = geoData;
      return geoData;
    },
  },
});
