<template>
  <div
    v-for="component in components"
    :key="component.__typename"
    :class="[
      'component',
      'dynamic-styles',
      ...Object.keys(getStyles(getComponentProps(component)?.styles) || {}).map((e) => `has-dynamic-style-${e}`),
    ]"
    :style="getStyles(getComponentProps(component)?.styles)"
  >
    <component :is="resovleDynamicComponent(component)" v-bind="getComponentProps(component)" />
  </div>
</template>

<script setup lang="ts">
defineProps<{ components: any }>();

const SectionScrollCTA = resolveComponent('SectionScrollCTA');
const HeroCard = resolveComponent('HeroCard');
const HeroTextBlock = resolveComponent('HeroTextBlock');

// @todo whould be nice to map these to a uid instead
const mapContentEntityTypenameToComponent = {
  HlpVideoModuleComponentsCard: HeroCard,
  HlpHeroModuleComponentsCard: HeroCard,
  HlpHeroModuleComponentsTextContent: HeroTextBlock,
  HlpImageModuleComponentsTextContent: HeroTextBlock,
  HlpVideoModuleComponentsSectionNavigationCta: SectionScrollCTA,
  HlpImageModuleComponentsSectionNavigationCta: SectionScrollCTA,
  HlpHeroModuleComponentsSectionNavigationCta: SectionScrollCTA,
} as any;

function resovleDynamicComponent(component: any) {
  return mapContentEntityTypenameToComponent[component.__typename];
}

function getComponentProps(component: any) {
  const props = {
    ...(Object.entries(component).filter(([key]) => key !== '__typename')[0][1] as any),
  };

  return props;
}

function getStyles(styles: any) {
  if (!styles) {
    return;
  }

  styles = Array.isArray(styles) ? styles : (styles || '').split(' ');

  const classNames = styles
    .map((e) => e)
    .map((className: string) => {
      const parts = className.split('-');
      let propertyName = parts.slice(0, -1).join('-');
      let mediaQueryPrefix = '';

      if (propertyName.includes(':')) {
        const parts = propertyName.split(':');
        mediaQueryPrefix = parts[0];
        propertyName = parts[1];
        mediaQueryPrefix = `${mediaQueryPrefix}-`;
      }

      // const classNameWithoutMedia = className.split(':').slice(-1)[0];
      const propertyValue = parts[parts.length - 1];
      return [`--${mediaQueryPrefix}${propertyName}`, propertyValue];
    });

  return Object.fromEntries(classNames);
}
</script>

<style lang="scss" scoped>
.component {
  display: grid;
  grid-area: stack;
  padding: 0.875rem;

  @include local-mixins.desktop {
    padding: 2.5rem;
  }
}
</style>
