<template>
  <div class="container">
    <NuxtLink :to="cta.href" class="link">
      <div class="col-1">
        <div class="title">{{ title }}</div>

        <div class="cta-button">
          {{ cta.title }}
          <div class="cta-icon">
            <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.2428 4.65301L6.70975 1.11996L7.64301 0.203369L12.8259 5.38628L7.64301 10.5692L6.70975 9.63593L10.2428 6.11955H0.476929V4.65301H10.2428Z"
                fill="#222222"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="col-2">
        <Picture v-if="cloudinary_asset?.length && sources?.length" :source-set="sources" />
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { SourceSet } from '~/composables/cloudinary';
import { AssetCloudinary } from '~/types/contentstack';

const props = defineProps<{
  // eslint-disable-next-line vue/prop-name-casing
  cloudinary_asset: AssetCloudinary[] | object;
  title: string;
  cta: {
    href: string;
    title: string;
  };
}>();

const cloudinaryAsset = computed(() => {
  if (!Array.isArray(props.cloudinary_asset)) {
    return [props.cloudinary_asset];
  }
  return props.cloudinary_asset;
});

const sources: SourceSet[] = [
  {
    cloudinary_asset: cloudinaryAsset.value,
    breakpoint: 'none',
    transform: { width: 100, height: 100, quality: 'auto' },
  },
  {
    cloudinary_asset: cloudinaryAsset.value,
    breakpoint: 'md',
    transform: { width: 190, height: 190, quality: 'auto' },
  },
];
</script>

<style lang="scss" scoped>
.container {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  backdrop-filter: blur(10px);
  color: #fff;
  width: 100%;
  max-height: 190px;
  justify-self: end;
  align-self: end;
  z-index: map.get(local-vars.$zindex, 'page');
  @media (min-width: calc(#{map.get(local-vars.$breakpoints, 'medium')})) {
    width: 460px;
  }
}

.link {
  display: flex;
  justify-content: space-between;
  color: inherit;
}

.col-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  gap: 0.625rem;
}

.col-2 {
  display: grid;
  place-items: center;
}

.title {
  font-family: local-vars.$ff-hlp;
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1.375rem;
  letter-spacing: -0.02em;

  @include local-mixins.desktop_and_tablet {
    font-size: 1.5rem;
    line-height: 1.95rem;
  }
}

.cta-button {
  font-family: local-vars.$ff-hlp;
  font-size: 0.875rem;
  font-weight: 900;
  text-transform: uppercase;
  background: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;

  @include local-mixins.desktop_and_tablet {
    font-size: 0.938rem;
  }
}

.cta-icon {
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 100%;
  display: grid;
  place-items: center;

  svg {
    width: 14px;
  }

  @include local-mixins.desktop_and_tablet {
    width: 40px;
    height: 40px;
  }
}
</style>
